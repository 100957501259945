import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css';

function Slider({ slider }) {

  const renderSlider = (item, index) => {
    return (
      <SwiperSlide key={index} style={{backgroundColor: 'white'}}>
        <div className="row">
          <div className="col-12 px-5 px-lg-0 mt-5 col-lg-5">
            <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} width="100%" height="100%" alt={item.Title} />
          </div>
          <div className="col-12 px-5 px-lg-4 mt-5 col-lg-7 d-grid flex-column">
            <div className="row">
              <div className="col-6" style={{ fontSize: 'var(--text2)' }}>
                {item.Title}
              </div>
              <div className="col-6 d-flex align-items-start justify-content-center">
                <hr style={{width:'100%'}}></hr>
              </div>
            </div>
            <div className="row d-flex">
              <div dangerouslySetInnerHTML={{__html: item.Description}} className="col-12" style={{paddingBottom: '1rem'}}>
                
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    )
  }


  return (
    <Swiper
    modules={[EffectFade, Pagination]}
    effect="fade"
    pagination={{ clickable: true }}
    >
      {slider.map(renderSlider)}
    </Swiper>
  )
}

export default Slider