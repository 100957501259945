import React, {useState} from 'react'
import Logo from './Media/Logo.png'
import { useNavigate } from 'react-router-dom'
import { Squash as Hamburger } from 'hamburger-react'

const Links = [
    {
        Title: "Home",
        Link: "/"
    },
    {
        Title: "About Us",
        Link: "/about-us"
    },
    {
        Title: "Services",
        Link: "/services"
    },

    {
        Title: "Contact Us",
        Link: "/contact-us"
    }
]


function Navbar() {
    const navigate = useNavigate();
    const renderLinks = (item, index) => {
        return (
            <div onClick={() => navigate(item.Link)} key={index} className="col-auto text-white" style={{ cursor: 'pointer' }}>
                {item.Title}
            </div>
        )
    }

    const [isOpen, setOpen] = useState(false)

    return (
        <div className='container mt-3' style={{ position: 'absolute', top: 0, right: 0, left: 0 }}>
            <div className="row d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                <div onClick={() => navigate("/")} className="col-auto d-flex" style={{ cursor: 'pointer' }}>
                    <svg width="107" height="124" viewBox="0 0 107 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.43037 113.718L0 100.241H3.23461L7.06781 112.177H5.45074L9.45321 100.239H12.3413L16.1938 112.175H14.634L18.5594 100.238H21.5434L17.1177 113.715H13.806L10.3772 103.243H11.2626L7.76044 113.717H4.4299L4.43037 113.718Z" fill="white" />
                        <path d="M21.8237 113.715L26.8724 102.346H29.4708L34.5722 113.713H31.8116L27.6361 103.678H28.6917L24.5195 113.714H21.8237V113.715ZM24.3732 111.278L25.0386 109.281L30.9177 109.28L31.6 111.277L24.3732 111.279V111.278Z" fill="white" />
                        <path d="M38.5516 113.712L33.6289 102.345H36.4873L40.7767 112.412H39.1041L43.4545 102.343H46.0694L41.1505 113.712H38.5521L38.5516 113.712Z" fill="white" />
                        <path d="M49.626 111.599L55.8136 111.598V113.709L47.0116 113.711L47.0098 102.343L55.6006 102.341V104.453L49.6246 104.454L49.626 111.599V111.599ZM49.4304 106.906L54.9033 106.905V108.967L49.4309 108.968V106.906H49.4304Z" fill="white" />
                        <path d="M64.6098 113.939C63.5701 113.939 62.6043 113.769 61.7123 113.429C60.8198 113.089 60.0468 112.605 59.3922 111.976C58.7377 111.347 58.2271 110.609 57.8612 109.762C57.4954 108.915 57.312 107.985 57.312 106.97C57.312 105.957 57.4945 105.026 57.8603 104.179C58.2261 103.331 58.7391 102.594 59.4002 101.965C60.0609 101.336 60.8372 100.851 61.7297 100.511C62.6217 100.171 63.5875 100 64.6272 100C65.7952 100 66.8541 100.202 67.8039 100.606C68.7538 101.01 69.5494 101.604 70.1917 102.387L68.1702 104.235C67.7207 103.709 67.2105 103.311 66.6392 103.042C66.0679 102.772 65.4486 102.638 64.7814 102.638C64.1522 102.638 63.5748 102.744 63.0486 102.956C62.522 103.168 62.0668 103.466 61.6817 103.851C61.2966 104.236 60.9985 104.692 60.7869 105.218C60.5753 105.745 60.4695 106.328 60.4695 106.97C60.4695 107.612 60.5758 108.196 60.7874 108.722C60.9994 109.249 61.2976 109.704 61.6827 110.089C62.0678 110.474 62.5234 110.772 63.0496 110.984C63.5758 111.195 64.1532 111.301 64.7823 111.301C65.4496 111.301 66.0688 111.163 66.6401 110.887C67.211 110.611 67.7212 110.21 68.1707 109.683L70.1926 111.531C69.5508 112.314 68.7552 112.911 67.8053 113.322C66.8555 113.733 65.79 113.939 64.6093 113.939H64.6098Z" fill="white" />
                        <path d="M77.5436 113.9C76.645 113.9 75.8165 113.754 75.059 113.462C74.301 113.17 73.6403 112.758 73.0775 112.228C72.5142 111.697 72.0783 111.075 71.7698 110.361C71.4613 109.646 71.3066 108.867 71.3066 108.022C71.3066 107.167 71.4609 106.385 71.7689 105.675C72.0773 104.966 72.5127 104.346 73.0761 103.816C73.6389 103.285 74.2963 102.873 75.0491 102.581C75.8014 102.288 76.6267 102.142 77.5257 102.142C78.4243 102.142 79.2495 102.286 80.0023 102.572C80.7547 102.859 81.4125 103.267 81.9758 103.798C82.5387 104.328 82.9746 104.951 83.2835 105.665C83.592 106.38 83.7467 107.164 83.7467 108.02C83.7467 108.864 83.5929 109.647 83.2844 110.367C82.976 111.087 82.5401 111.712 81.9772 112.242C81.4144 112.773 80.7566 113.182 80.0042 113.469C79.2514 113.755 78.4313 113.899 77.544 113.899L77.5436 113.9ZM77.5271 111.675C78.0359 111.675 78.5042 111.586 78.9317 111.407C79.3591 111.229 79.7353 110.974 80.0602 110.644C80.3851 110.313 80.6367 109.929 80.8153 109.491C80.994 109.052 81.0834 108.562 81.0829 108.021C81.0829 107.48 80.9936 106.99 80.8149 106.551C80.6362 106.113 80.3842 105.728 80.0597 105.398C79.7348 105.068 79.3586 104.813 78.9307 104.635C78.5028 104.456 78.0345 104.367 77.5257 104.367C77.0165 104.367 76.5458 104.456 76.1127 104.635C75.6796 104.814 75.3035 105.068 74.9842 105.399C74.6649 105.729 74.4157 106.114 74.2375 106.552C74.0588 106.99 73.9695 107.48 73.9699 108.022C73.9699 108.563 74.0593 109.053 74.238 109.492C74.4166 109.93 74.6659 110.314 74.9851 110.645C75.3044 110.975 75.6806 111.229 76.1141 111.408C76.5472 111.586 77.0179 111.676 77.5271 111.675Z" fill="white" />
                        <path d="M85.7284 113.704L85.7266 102.336L90.6474 102.335C91.6649 102.335 92.5419 102.5 93.2782 102.83C94.0146 103.16 94.5831 103.633 94.9837 104.25C95.3843 104.867 95.5846 105.598 95.5851 106.442C95.5851 107.298 95.3848 108.031 94.9846 108.643C94.584 109.255 94.016 109.721 93.2796 110.04C92.5433 110.36 91.6663 110.52 90.6488 110.52H87.1734L88.3748 109.383L88.3758 113.703H85.7284V113.704ZM88.3748 109.676L87.1729 108.425H90.5021C91.3033 108.425 91.9094 108.251 92.3209 107.905C92.7323 107.558 92.9378 107.071 92.9378 106.443C92.9378 105.815 92.7318 105.33 92.3204 104.989C91.909 104.648 91.3024 104.478 90.5016 104.478H87.1725L88.3739 103.228L88.3748 109.676ZM92.9716 113.702L90.1127 109.578H92.9387L95.7976 113.702H92.9716Z" fill="white" />
                        <path d="M100.278 111.59L106.465 111.589V113.7L97.6635 113.701L97.6616 102.333L106.252 102.332V104.443L100.276 104.444L100.278 111.59V111.59ZM100.082 106.896L105.555 106.895V108.958L100.082 108.959V106.896Z" fill="white" />
                        <path d="M87.5739 52.0819L82.1901 49.792C74.2286 70.48 45.0057 75.9413 30.1749 59.6632C27.5372 61.6785 25.1265 61.8738 24.2769 61.8696C41.1791 83.1899 77.7779 77.5697 87.5739 52.0824V52.0819Z" fill="white" />
                        <path d="M52.9203 5.41202C66.7048 5.41202 78.8411 14.2579 83.12 27.4236L88.6756 25.5971C78.8669 -8.46477 27.1694 -8.54971 17.2139 25.447L22.7624 27.2967C27.0774 14.2069 39.1968 5.41202 52.9203 5.41202Z" fill="white" />
                        <path d="M32.3375 35.0285C43.8029 13.2245 71.2771 16.309 68.7684 38.1531L74.2057 38.4655C76.5047 26.2543 64.5345 10.8006 48.0278 14.952C25.7521 22.1119 27.6546 41.651 14.162 43.6224C7.54652 44.5892 6.08268 36.4341 6.08268 36.4341C6.08268 36.4341 4.52922 36.8248 1.73291 37.3717C5.02233 55.4985 23.3466 51.7907 32.338 35.028L32.3375 35.0285Z" fill="white" />
                        <path d="M93.1586 33.154C77.7104 30.8669 76.6163 50.9373 63.4858 54.3252C50.87 56.3938 49.2551 44.5762 54.5287 37.4516C60.1044 37.4177 60.0847 41.8272 60.0678 41.8257C51.7497 44.6616 60.0641 51.9046 64.328 44.5672C64.3191 44.5696 64.313 44.5729 64.3041 44.5752C64.8652 43.6348 65.0519 42.1089 65.0519 42.1089C65.0519 42.1089 65.0571 42.1169 65.0646 42.1296C65.6229 29.7127 47.6749 28.3703 40.3383 40.0289C33.7717 49.6906 24.5964 60.0238 15.8692 57.6829C12.463 58.1014 20.2377 62.2434 23.8603 61.9093C25.0257 61.9046 28.4672 61.6361 31.5088 58.8465C31.5088 58.8465 31.4802 58.8531 31.4291 58.8654C37.7879 54.6229 42.6908 44.4441 48.5054 39.7604C38.8609 58.8942 66.6509 68.4908 78.5545 50.339C84.2254 42.684 86.478 37.9966 92.6144 38.5435C98.7508 39.0904 99.683 46.9024 99.683 46.9024L104.732 46.5901C104.732 46.5901 104.033 34.3251 93.1586 33.1535V33.154Z" fill="white" />
                    </svg>

                </div>

                <div className="col-auto d-none d-lg-flex">
                    <div className="row ">

                        {Links.map(renderLinks)}
                    </div>
                </div>
                <div className="col-auto d-flex d-lg-none">
                    <Hamburger toggled={isOpen} color="white" toggle={setOpen} rounded />
                </div>

                <div className="row d-block text-end">
                    {isOpen && (
                        Links.map(renderLinks) 
                    )}
                </div>

            </div>
        </div>
    )
}

export default Navbar