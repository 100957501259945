import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Background from './Media/Background.png'
import Form from './Media/Form.png'
import './Contact.css'
import { AxiosInstance } from '../Axios/Axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFullNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handleTelephoneChange = (e) => {
        setPhone(e.target.value);
    };


    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);




        const data = {
            data: {
                name: name,
                email: email,
                phone: phone,
                message: message
            }
        };

        if (!name || !email || !phone || !message) {
            toast.warning('Please fill all the fields', {
                position: 'top-right',
                autoClose: 3000, // Notification will close after 3000ms (3 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
            return;
        }
        else {



            try {
                await AxiosInstance.post('/api/emails/', data);
                toast.success('Email sent successfully', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');

            } catch (error) {
                toast.error('Error sending email', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.error('Error sending email:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (window.location.hash === '#form') {
            const formElement = document.getElementById('contact-form');
            formElement.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <div className='container-fluid h-100'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Helmet>
                <title>
                    WaveCore | Contact Us
                </title>

            </Helmet>
            <div className="row" style={{
                backgroundImage: `url(${Background})`,
                height: '70vh',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <div className="col-12 d-flex align-items-center text-white justify-content-center" style={{ fontSize: 'var(--title)' }}>
                    Contact Us
                </div>
                <div className="col-12 text-white text-center">
                    Let’s Embark Towards A Vibrant Future Together.
                </div>
            </div>
            <div className="container" style={{ marginTop: "10rem" }}>
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-lg-6" style={{ fontSize: 'var(--title)' }}>
                        Talk To Us
                    </div>
                    <div className="col-12  col-lg-6">
                        <div className="row">
                            <div className="col-12 col-lg-6 mt-5">
                                <div className="row">
                                    <div className="col-12" style={{ fontSize: 'var(--text2)' }}>
                                        Contact Info
                                    </div>
                                    <div className="col-12 mt-4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.6589 2C19.9889 2 22.4999 4.717 22.4999 8.32V15.188C22.4999 17.032 21.8479 18.698 20.6629 19.88C19.5999 20.939 18.2209 21.5 16.6749 21.5H6.82188C5.27888 21.5 3.90088 20.94 2.83688 19.88C1.65188 18.698 0.999878 17.032 0.999878 15.188V8.32C0.999878 4.717 3.51088 2 6.84088 2H16.6589ZM16.6589 3.5H6.84088C4.32588 3.5 2.49988 5.527 2.49988 8.32V15.188C2.49988 16.631 2.99588 17.92 3.89588 18.817C4.67188 19.592 5.68488 20 6.82488 20H16.6589C16.6609 19.998 16.6689 20 16.6749 20C17.8159 20 18.8279 19.592 19.6039 18.817C20.5049 17.92 20.9999 16.631 20.9999 15.188V8.32C20.9999 5.527 19.1739 3.5 16.6589 3.5ZM18.2349 8.1288C18.4959 8.4498 18.4469 8.9218 18.1259 9.1838L13.6819 12.7958C13.1199 13.2418 12.4479 13.4648 11.7769 13.4648C11.1079 13.4648 10.4409 13.2438 9.88288 12.8018L5.39788 9.1858C5.07488 8.9258 5.02488 8.4528 5.28388 8.1308C5.54488 7.8098 6.01688 7.7588 6.33888 8.0178L10.8199 11.6298C11.3829 12.0758 12.1759 12.0758 12.7429 11.6258L17.1789 8.0198C17.5009 7.7568 17.9729 7.8068 18.2349 8.1288Z" fill="#333333" />
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;info@wavecore.org
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                            <div className="col-auto">

                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.35947 5.06081C4.36147 5.06081 4.31147 5.11281 4.24847 5.17481C4.00647 5.40981 3.50547 5.89881 3.50042 6.92181C3.49247 8.35281 4.43347 11.0098 8.96447 15.5398C13.4745 20.0478 16.1275 20.9998 17.5615 20.9998H17.5825C18.6055 20.9948 19.0935 20.4928 19.3295 20.2518C19.4015 20.1778 19.4595 20.1238 19.5005 20.0898C20.4965 19.0878 21.0065 18.3418 21.0015 17.8638C20.9945 17.3758 20.3875 16.7988 19.5485 16.0008C19.2815 15.7468 18.9915 15.4708 18.6855 15.1648C17.8925 14.3738 17.4995 14.5088 16.6355 14.8128C15.4405 15.2318 13.8005 15.8018 11.2525 13.2528C8.70047 10.7028 9.27147 9.06481 9.68947 7.86981C9.99147 7.00581 10.1295 6.61181 9.33547 5.81781C9.02447 5.50781 8.74547 5.21381 8.48847 4.94381C7.69547 4.10981 7.12347 3.50681 6.63847 3.49981H6.63047C6.15147 3.49981 5.40747 4.01181 4.35447 5.06481C4.35747 5.06181 4.35947 5.06081 4.35947 5.06081ZM17.5625 22.4998C15.0705 22.4998 11.8215 20.5158 7.90447 16.6008C3.97247 12.6698 1.98547 9.41081 2.00039 6.91381C2.00947 5.26381 2.87447 4.41881 3.19947 4.10181C3.21647 4.08081 3.27447 4.02381 3.29447 4.00381C4.72847 2.56881 5.69547 1.98881 6.65747 1.99966C7.77447 2.01481 8.56947 2.85081 9.57547 3.90981C9.82447 4.17181 10.0945 4.45681 10.3955 4.75681C11.8555 6.21681 11.4395 7.40781 11.1055 8.36381C10.7415 9.40681 10.4265 10.3068 12.3125 12.1928C14.2005 14.0788 15.1005 13.7638 16.1395 13.3968C17.0965 13.0628 18.2845 12.6448 19.7465 14.1048C20.0425 14.4008 20.3235 14.6678 20.5825 14.9148C21.6465 15.9258 22.4865 16.7248 22.5007 17.8448C22.5125 18.7998 21.9325 19.7728 20.5005 21.2058L19.8665 20.7758L20.4025 21.2998C20.0855 21.6248 19.2415 22.4908 17.5905 22.4998H17.5625Z" fill="#333333" />
                                                </svg>
                                            </div>
                                            <div className="col-auto" style={{ textAlignLast: 'justify' }}>

                                                UAE +971 52 444 6665<br />
                                                USA +1 (781) 885-8339
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mt-5">
                                <div className="row">
                                    <div className="col-12" style={{ fontSize: 'var(--text2)' }}>
                                        Social Media
                                    </div>
                                    <div className="col-12 mt-4">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_41_698)">
                                                <path d="M15.595 4.20459H8.40505C6.08505 4.20459 4.20471 6.08526 4.20471 8.40492V15.5949C4.20471 17.9149 6.08538 19.7953 8.40538 19.7953H15.5947C17.9147 19.7953 19.7954 17.9146 19.7954 15.5946V8.40492C19.7954 6.08492 17.915 4.20459 15.595 4.20459Z" fill="white" />
                                                <path d="M17.0729 1H6.92709C3.65334 1 1 3.65381 1 6.92709V17.0729C1 20.3467 3.65381 23 6.92756 23H17.0724C20.3462 23 23 20.3462 23 17.0724V6.92709C23 3.65334 20.3467 1 17.0729 1Z" stroke="#333333" strokeWidth="2" strokeMiterlimit="10" />
                                                <path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" stroke="#333333" strokeWidth="2" strokeMiterlimit="10" />
                                                <path d="M18 6C18.5523 6 19 5.55229 19 5C19 4.44772 18.5523 4 18 4C17.4477 4 17 4.44772 17 5C17 5.55229 17.4477 6 18 6Z" fill="#333333" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_41_698">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        &nbsp;&nbsp;&nbsp;&nbsp;wavecoreorg
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                            <div className="col-auto">

                                            </div>
                                        </div>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_41_705)">
                                                <path d="M7.20005 9.60005V17.6M16.8 17.6V13.6C16.8 12.7514 16.4629 11.9374 15.8628 11.3373C15.2627 10.7372 14.4487 10.4 13.6 10.4C12.7514 10.4 11.9374 10.7372 11.3373 11.3373C10.7372 11.9374 10.4 12.7514 10.4 13.6V17.6V9.60005M6.40005 7.20005H8.00005M2.40005 0.800049H21.6C22.0244 0.800049 22.4314 0.96862 22.7314 1.26868C23.0315 1.56874 23.2 1.9757 23.2 2.40005V21.6C23.2 22.0244 23.0315 22.4314 22.7314 22.7314C22.4314 23.0315 22.0244 23.2 21.6 23.2H2.40005C1.9757 23.2 1.56874 23.0315 1.26868 22.7314C0.96862 22.4314 0.800049 22.0244 0.800049 21.6V2.40005C0.800049 1.9757 0.96862 1.56874 1.26868 1.26868C1.56874 0.96862 1.9757 0.800049 2.40005 0.800049Z" stroke="#333333" strokeWidth="2" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_41_705">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        &nbsp;&nbsp;&nbsp;&nbsp;wavecoreorg
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="row d-flex">
                                    <div className="col-1">

                                        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.24951 0C12.7985 0 16.4995 3.729 16.4995 8.313C16.4995 13.948 10.0435 19.5 8.24951 19.5C6.45551 19.5 -0.000488281 13.948 -0.000488281 8.313C-0.000488281 3.729 3.70051 0 8.24951 0ZM8.24951 1.5C4.52751 1.5 1.49951 4.557 1.49951 8.313C1.49951 13.092 7.12351 17.748 8.24951 17.996C9.37551 17.747 14.9995 13.091 14.9995 8.313C14.9995 4.557 11.9715 1.5 8.24951 1.5ZM8.25051 5C10.0425 5 11.5005 6.458 11.5005 8.251C11.5005 10.043 10.0425 11.5 8.25051 11.5C6.45851 11.5 5.00051 10.043 5.00051 8.251C5.00051 6.458 6.45851 5 8.25051 5ZM8.25051 6.5C7.28551 6.5 6.50051 7.285 6.50051 8.251C6.50051 9.216 7.28551 10 8.25051 10C9.21551 10 10.0005 9.216 10.0005 8.251C10.0005 7.285 9.21551 6.5 8.25051 6.5Z" fill="black" />
                                        </svg>
                                    </div>
                                    <div className="col-11 contact_info" style={{ fontSize:"var(--text1)" }}>


                                        UAE - Masdar City Free Zone, Abu Dhabi, United Arab Emirates
                                        <br />
                                        USA - 16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='contact-form' className="container" style={{ marginBottom: '10rem', marginTop: "10rem" }}>
                <div className="row" style={{
                    backgroundImage: `url(${Form})`,
                    height: "800px",
                }}>
                    <div className="col-7 d-none d-lg-flex">

                    </div>
                    <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center" style={{
                        backgroundColor: 'rgba(51, 51, 51, 0.6)'
                    }}>
                        <form onSubmit={handleSubmit}>
                            <div className="row px-5 d-flex align-items-center justify-content-center">
                                <div className="col-12 mt-5">
                                    <input
                                        type="text"
                                        className='input'
                                        placeholder='Enter your name'
                                        name="name"
                                        value={name}

                                        onChange={handleFullNameChange}
                                    />
                                </div>
                                <div className="col-12 mt-5">
                                    <input
                                        type="email"
                                        className='input'
                                        placeholder='example@domain.com'
                                        name="email"

                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                </div>
                                <div className="col-12 mt-5">
                                    <input
                                        type="tel"
                                        className='input'
                                        placeholder='Phone'
                                        name="phone"

                                        value={phone}
                                        onChange={handleTelephoneChange}
                                    />
                                </div>
                                <div className="col-12 mt-5">
                                    <input
                                        type="text"
                                        className='input'
                                        placeholder='Enter your message...'
                                        name="message"
                                        value={message}

                                        onChange={handleMessageChange}
                                    />
                                </div>
                                <div className="col-12 mt-5">
                                    <input
                                        type="submit"
                                        disabled={loading}
                                        className='px-5 py-2'
                                        value={`${loading ? 'Sending email...' : 'Join community'}`}

                                        style={{
                                            backgroundColor: 'var(--blue)',
                                            color: 'white',
                                            border: 'none'
                                        }}
                                    />
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact