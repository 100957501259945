import React, { useEffect, useState } from 'react'
import Background from './Media/Background.png'
// import Background1 from './Media/Background1.png'
import { RequestManager } from '../Requests/Requests';

import Marine from './Media/Marine.png'
import Carbon from './Media/Carbon.png'
import Infrastructure from './Media/Infrastructure.png'
import { Helmet } from 'react-helmet';
import { Audio } from 'react-loader-spinner'
import TransformSlider from '../Transformer/TransformSlider';
import Slider from './Slider';

function Services() {
    const [marine, setMarine] = useState([]);
    const [carbon, setCarbon] = useState([]);
    const [infrastructure, setInfrastructure] = useState([]);
    const [loadingMarine, setLoadingMarine] = useState(true);
    const [loadingCarbon, setLoadingCarbon] = useState(true);
    const [loadingInfrastructure, setLoadingInfrastructure] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingMarine(true);
                const marineData = await RequestManager.fetchMarineSlider();
                const transformedMarineData = TransformSlider(marineData?.data);
                setMarine(transformedMarineData);
            } catch (error) {
                console.error("Error fetching or transforming marine data:", error);
                setMarine([]);
            } finally {
                setLoadingMarine(false);
            }

            try {
                setLoadingCarbon(true);
                const carbonData = await RequestManager.fetchCarbonSlider();
                const transformedCarbonData = TransformSlider(carbonData?.data);
                setCarbon(transformedCarbonData);
            } catch (error) {
                console.error("Error fetching or transforming carbon data:", error);
                setCarbon([]);
            } finally {
                setLoadingCarbon(false);
            }

            try {
                setLoadingInfrastructure(true);
                const infrastructureData = await RequestManager.fetchInfrastructure();
                const transformedInfrastructureData = TransformSlider(infrastructureData?.data);
                setInfrastructure(transformedInfrastructureData);
            } catch (error) {
                console.error("Error fetching or transforming infrastructure data:", error);
                setInfrastructure([]);
            } finally {
                setLoadingInfrastructure(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='container-fluid h-100'>
            <Helmet>
                <title>
                    WaveCore | Services
                </title>

            </Helmet>
            <div className="row" style={{
                backgroundImage: `url(${Background})`,
                height: '70vh',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <div className="col-12 d-flex align-items-center text-white justify-content-center" style={{ fontSize: 'var(--title)' }}>
                    Services
                </div>
            </div>


            <div className="container" style={{ marginTop: '10rem' }}>
                <div className="row">
                    <div className="col-5" style={{ fontSize: 'var(--subTitle' }}>
                        Marine Ecosystem<br />
                        Enhancement
                    </div>
                    <div className="col-7" style={{ borderBottom: '1px solid #B8B8B8', marginBottom: '6%' }}>

                    </div>
                </div>
                <div className="row mt-5 ">
                    <div className="col-12 px-4">
                        <img src={Marine} alt="Marine Ecosystem" width="100%" height="100%" />
                    </div>
                </div>


            </div>
            <div className="row mt-5">
                <div className="col-12 mt-5 px-0">
                    {loadingMarine ? (
                        <div className="d-flex align-items-center justify-content-center">
                            <Audio
                                height="80"
                                width="80"
                                radius="9"
                                color="blue"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                            />
                        </div>
                    ) : (
                        <Slider slider={marine} />
                    )}
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-5" style={{ fontSize: 'var(--subTitle' }}>
                        Carbon & Habitat<br />
                        Solutions
                    </div>
                    <div className="col-7" style={{ borderBottom: '1px solid #B8B8B8', marginBottom: '6%' }}>

                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 px-4">
                        <img src={Carbon} alt="Carbon & Habitat Solutions" width="100%" height="100%" />
                    </div>
                </div>


            </div>
            <div className="row mt-5">
                <div className="col-12 mt-5 px-0">
                    {loadingCarbon ? (
                        <div className="d-flex align-items-center justify-content-center">
                            <Audio
                                height="80"
                                width="80"
                                radius="9"
                                color="blue"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                            />
                        </div>
                    ) : (
                        <Slider slider={carbon} />
                    )}
                </div>
            </div>
            <div className="container" style={{ marginTop: '10rem' }}>
                <div className="row">
                    <div className="col-7" style={{ fontSize: 'var(--subTitle' }}>
                        Sustainable Infrastructure<br />
                        & Education
                    </div>
                    <div className="col-5" style={{ borderBottom: '1px solid #B8B8B8', marginBottom: '6%' }}>

                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12 px-4">
                        <img src={Infrastructure} alt="Carbon & Habitat Solutions" width="100%" height="100%" />
                    </div>
                </div>


            </div>
            <div className="row mt-5" style={{ marginBottom: "10rem" }}>
                <div className="col-12 mt-5 px-0">
                    {loadingInfrastructure ? (
                        <div className="d-flex align-items-center justify-content-center">
                            <Audio
                                height="80"
                                width="80"
                                radius="9"
                                color="blue"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                            />
                        </div>
                    ) : (
                        <Slider slider={infrastructure} />
                    )}
                </div>
            </div>


        </div>
    )
}

export default Services