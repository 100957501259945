import { AxiosInstance } from "../Axios/Axios"



const endpoints = {
    fetchAboutUs: `/api/about-us`,
    fetchMarineSlider: `/api/marine-sliders?populate=*&sort[0]=id`,
    fetchCarbonSlider: `/api/carbon-sliders?populate=*&sort[0]=id`,
    fetchInfrastructure: `/api/infrastructures?populate=*&sort[0]=id`,
    sendEmail: `/api/emails`,
}

export const RequestManager = {
      async fetchAboutUs() {
        try {
          const response = await AxiosInstance.get(endpoints.fetchAboutUs);
          return response?.data;
        } catch (error) {
          return {
            success: false,
            error,
          };
        }
      },
      async fetchMarineSlider() {
        try {
          const response = await AxiosInstance.get(endpoints.fetchMarineSlider);
          return response?.data;
        } catch (error) {
          return {
            success: false,
            error,
          };
        }
      },
      async fetchCarbonSlider() {
        try {
          const response = await AxiosInstance.get(endpoints.fetchCarbonSlider);
          return response?.data;
        } catch (error) {
          return {
            success: false,
            error,
          };
        }
      },
      async fetchInfrastructure() {
        try {
          const response = await AxiosInstance.get(endpoints.fetchInfrastructure);
          return response?.data;
        } catch (error) {
          return {
            success: false,
            error,
          };
        }
      },
      async sendEmail() {
        try {
          const response = await AxiosInstance.post(endpoints.sendEmail);
          return response?.data;
        } catch (error) {
          return {
            success: false,
            error,
          };
        }
      },
      
    
}