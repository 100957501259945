function TransformAboutUs(data) {
    return {
      id: data.id,
      ...data.attributes,
      Image: data.attributes.Image?.data?.attributes?.url,
     
     
    };
  }
  
  export default TransformAboutUs;
  